<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import Spinner from "../../../components/spinner.vue";

const loading = ref(true);

const props = defineProps({
    paymentMethod:{
        type: Object,
    },
    paymentToken: String,
});

onMounted(()=> {
  setSSLCommerze();
});

watch(() => props.paymentMethod.gateway_configuration, setSSLCommerze)

const sslcommerz_endpoint = computed(function get_sslcommerz_endpoint( ){
  //console.log({gateway_configuration: props.paymentMethod } )
  if( props.paymentMethod.gateway_configuration && props.paymentMethod.gateway_configuration.getaway_url) {


    const url = new URL( props.paymentMethod.gateway_configuration.getaway_url );
    return url.toString(); 
  }
  return '';
});

const route = useRoute();


function setSSLCommerze(data){

   console.log( data );

  const sslcommerzBtn = document.getElementById("sslczPayBtn");

  //console.log({sslcommerzBtn});
  
  if( sslcommerzBtn ) {

    sslcommerzBtn.addEventListener('click', function(){
      if( confirm('Are you sure?')) {

      }
    });

    var obj = {};
        
    obj.redirect_url = route.query.redirect_url;
    
    obj.token = props.paymentToken;
    obj.payment_method_id = props.paymentMethod.id;
    
    sslcommerzBtn.postdata = obj;
    
    enqueueSSLCommerzScript();

  }
}

function enqueueSSLCommerzScript( ) {
    var script = document.createElement("script"), 
        tag = document.getElementsByTagName("script")[0];
      script.src = "https://sandbox.sslcommerz.com/embed.min.js?" + Math.random().toString(36).substring(7); // USE THIS FOR SANDBOX
      //script.src = "https://seamless-epay.sslcommerz.com/embed.min.js?" + Math.random().toString(36).substring(7); //USE THIS FOR LIVE
    tag.parentNode.insertBefore(script, tag);
    loading.value = false
};

</script>

<template>

    <button 
        id="sslczPayBtn"
        class="btn btn-primary btn-lg btn-block w-full" 
        postdata="--postdata--"
        order="--order--"
        :endpoint="sslcommerz_endpoint"
         
    > 
                
        <Spinner v-if="loading"/>
        <div v-else>Pay Now</div> 
    </button>

</template>