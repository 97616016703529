<template>
     <section class="text-gray-600 body-font">
                <h3 class="sm:text-3xl text-xl font-medium title-font mb-4 text-gray-900 text-center">How to pay with <span class="text-blue-600 font-bold">Rocket</span></h3>

        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap -mx-4 -mb-10 text-center">
                <div class="sm:w-1/2 mb-10 px-4">
                    <div class="rounded-lg h-auto overflow-hidden">
                        <img alt="content" class="object-cover object-center h-full w-full"
                            src="http://shovabd.com/images/payment/rocket-payment.jpg">
                    </div>
                    <p class="title-font font-medium text-gray-900 mt-2 mb-3">Payment using USSD</p>
                    
                </div>
                <div class="sm:w-1/2 mb-10 px-4">
                    <div class="rounded-lg h-auto overflow-hidden">
                        <img alt="content" class="object-cover object-center h-full w-full"
                            src="https://www.amberit.com.bd/img/billing/rocket-app.png">
                    </div>
                    <p class="title-font font-medium text-gray-900 mt-2 mb-3">Payment using Rocket App</p>
                    
                </div>
            </div>
        </div>
    </section>
</template>
