<template>
  <div class="w-full bg-white rounded">
    
   
    <div v-for="group in paymentMethodGroups" :key="group.name"  >

      
      <h2 class="text-lg font-semibold" v-if="!group.is_office_payment">{{ group.name }}</h2>
      
      <div class="flex flex-wrap gap-16 mb-8 pt-2  text-left justify-start" v-if="!group.is_office_payment">
        <div  class="" style="cursor: pointer;" v-for="payment_method in group.methods" :key="payment_method.id" >


          <label class="border shadow rounded px-6 hover:shadow-none  overflow-hidden flex  items-center gap-4 cursor-pointer"
              :class="{
                'bg-blue-300': payment_method_id == payment_method.id, 
                'hover:bg-blue-50':payment_method_id != payment_method.id
              }"
              
          >
            <input type="radio" 
              required
              @change="emit_id($event, payment_method)"
              :checked=" payment_method_id == payment_method.id" 
            >

            <img 
              alt="content" 
              class="object-cover object-center h-12"
              :src="payment_method.icon_url"
            >
            </label>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>

import SSLCommerzeButton from "./SSLCommerzButton.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";


const props = defineProps({
  modelValue: {type: Number, default: null},
  methods: {default:[] ,type: Array },
  paymentMethodGroups: {default: [], type: Array }
});


const emit = defineEmits([
  'update:modelValue',
  'changeMethod',
]);


const payment_method_id = ref();

function emit_id( event, payment_method ){
  event.stopPropagation();
  

  payment_method_id.value = payment_method.id;
 
  emit( 'update:modelValue',payment_method.id );
  emit( 'changeMethod', payment_method );
}


onMounted(()=> { })


</script>

<style scoped>

</style>