<template>
  <TransactionConfirm v-if="transactionSuccess"></TransactionConfirm>
  <template v-else>
    <form class="text-gray-600 body-font" @submit.prevent="submitTransaction">
      <div class="container py-4 mx-auto">
        <div class="flex flex-col text-center w-full">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Regular Batch Payment</h1>

          <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
            <span>Dear </span>
            <span class="text-lg text-purple-600 font-bold">{{ studentName }}</span>
          </p>

          <p class="font-semibold text-green-500 text-xl mb-4">Thank you for your effort! </p>

          <p>
            <span>We are accepting </span>
            <span class="text-blue-600 font-bold text-xl"> {{ merchantName }} </span>
            <span> Payment</span>
          </p>

          <p>
            <span>Please </span>
            <span class="text-blue-600 font-bold text-xl"> {{ payment_type }} </span>
            <span> (not {{ other_payment_types }}) to </span>
            <span class="text-red-700 font-bold text-xl"> {{ accountNumber }}</span>
          </p>

          <p class="text-blue-600 font-bold m-5" v-if="nb">
            <span>NB: </span>
            <span>{{ nb }}</span>
          </p>

          <div class="mb-5 mt-3 mx-auto xs:w-8/12 sm:w-6/12 lg:w-4/12 w-full payment-table">

            <p>
              <span class="row-1">Student:</span>
              <span class="row-2 text-blue-600 font-bold">{{ studentName }}</span>
            </p>

            <p>
              <span class="row-1">Addmission Fee:</span>
              <span class="row-2 text-blue-600 font-bold">
                <money-amount :amount="admission_fee"></money-amount>
              </span>
            </p>

            <p>
              <span class="row-1">Charge: </span>
              <span class="row-2 text-blue-600 font-bold">
                <money-amount :amount="charge"></money-amount>
              </span>
            </p>

            <p>
              <span class="row-1 font-semibold">Payable amount: </span>
              <span class="row-2 text-red-700 font-bold text-xl">
                <money-amount :amount="payable_amount"></money-amount>
              </span>
            </p>

          </div>


          <div class="lg:w-1/2 w-full md:w-2/3 mx-auto">
            <div class="flex flex-wrap -m-2 justify-center">
              <div class="xs:w-8/12 sm:w-6/12 lg:w-7/12 w-full p-2">

                <div class="relative mb-5">

                  <label class="font-semibold">
                    <span>Please provide </span>
                    <span class="text-blue-600 font-bold text-xl">{{ merchantName }} </span>
                    <span> A/C No.</span>
                  </label>

                  <input-text v-model="account_number" input-class="text-center"></input-text>

                </div>

                <div class="relative mb-5">
                  <label class="font-semibold">
                    <span>Please provide your </span>
                    <span class="text-blue-600 font-bold text-xl"> Transaction ID </span>
                  </label>
                  <input-text v-model="transaction" input-class="text-center"></input-text>
                </div>


                <div class="relative mb-5">
                  <label class="font-semibold">
                    <span>Amount you have </span>
                    <span class="text-blue-600 font-bold text-xl"> Paid </span>
                  </label>
                  <input-text type="number" v-model="paid_amount" input-class="text-center"></input-text>
                </div>

              </div>

              <div class="p-2 w-full">
                <btn variant="success" size="lg" type="submit">Submit</btn>
              </div>

            </div>

          </div>
        </div>
      </div>
    </form>

    <section class="text-gray-600 body-font" v-if="instructions">
      <h3 class="sm:text-2xl mt-5 text-xl font-medium title-font mb-4 text-gray-900 text-center">How to pay with <span
          class="text-blue-600 font-bold">{{ merchantName }}</span></h3>
      <div class="container px-5 py-10 mx-auto">
        <div v-html="instructions" class="border rounded p-3 w-full md:w-8/12 mx-auto"></div>
      </div>
    </section>

  </template>
</template>

<script>
import bkash from "./payment_methods/bkash-demo.vue"
import rocket from "./payment_methods/rocket-demo.vue"
import nagad from "./payment_methods/nagad-demo.vue"
import MoneyAmount from "../../../../components/money-amount.vue"
import InputText from "../../../../components/input/input-text.vue"
import axios from "../../../../store/axios";
import Btn from "../../../../components/btn";
import Container from "../../../layouts/container";
import TransactionConfirm from "./transaction-confirm";

export default {
  name: 'payment-transaction',
  emits: ['transactionSuccess'],
  components: {
    Container,
    Btn,
    bkash,
    rocket,
    nagad,
    MoneyAmount,
    InputText,
    TransactionConfirm
  },

  data() {
    return {
      transaction: '',
      is_methodBkash: true,
      is_methodRocket: false,
      is_methodNagad: false,
      loading: false,
      account_number: '',
      paid_amount: ''
    }
  },
  props: {
    transactionSubmitting: {type: Boolean, default: false},
    modelValue: {type: String, default: ''},
    accountNumber: {type: String, default: null, required: true},
    accountType: {type: String, default: null, required: true},
    studentName: {type: String, default: null, required: true},
    merchantName: {type: String, default: null, required: true},
    payableAmount: {type: Number, default: null, required: true},
    chargePercentage: {type: Number, default: null, required: true},
    nb: {type: String, default: null},
    other_payment_types: {type: String, default: null},
    payment_type: {type: String, default: null},
    instructions: {type: String, default: null},
    paymentMethodId: {type: Number, default: null},
  },
  created() {

  },
  computed: {
    transactionSuccess() {
      return this.$route.query['transaction-success'];
    },
    token() {
      return this.$route.query.token;
    },
    admission_fee() {
      return this.payableAmount;
    },
    charge() {
      return (this.admission_fee * this.chargePercentage / 100).toFixed(2);
    },
    payable_amount() {
      return this.admission_fee + Number(this.charge);
    }

  },
  methods: {
    submitTransaction() {
      this.loading = true;
      axios().post(`/data-collection-forms/process-payment`, {
        payment_method_id: this.paymentMethodId,
        transaction: this.transaction,
        token: this.token,
        account_number: this.account_number,
        paid_amount: this.paid_amount
      })
          .then(({data}) => {

            this.$resetRouteQueryParams({'transaction-success': true});
            this.$emit('transactionSuccess', data);

          })
          .catch((err) => {
          })
          .finally(() => this.loading = false);

    }

  },

  watch: {
    loading(value) {
      this.$emit('update:transactionSubmitting', value);
    }
  }

}
</script>

<style scoped>
.payment-table {
  @apply border shadow rounded bg-white
}

.payment-table p {
  @apply border-b border-dotted grid grid-cols-2 w-full
}

.payment-table p .row-1,
.payment-table p .row-2 {
  @apply px-2 py-1.5
}

.payment-table p .row-1 {
  @apply text-left
}

.payment-table p .row-2 {
  @apply border-l text-right
}

</style>
