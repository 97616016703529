<template>

    <container max-w="full" :loading="transaction_submitting || loading" text-on-loading="Processing">
      <div v-if="!loading" style="min-height: 300px">
        <section class="text-gray-600 body-font"
                 v-if="payment_methods.length > 1 && !selected_payment_method_id && !transaction_success" >

          <payment-methods :methods="payment_methods" v-model="selected_payment_method_id"></payment-methods>
        </section>

        <div v-else>

          <div v-if="payment_methods.length > 1 && !transaction_success" class="text-center mb-4 my-6">
              <span class="font-semibold">Account Type: </span>
              <span>
                <img :src="selected_payment_method.icon_url"
                          :alt="selected_payment_method.merchant_name"
                          class="h-8 inline-block"/>
              </span>
              <btn  @click.prevent="selected_payment_method_id = null"
                    :disabled="transaction_submitting"
                    class="ml-2"
                    variant="success-outline"
                    size="sm">Change</btn>
          </div>

          <payment-transaction
              v-model:transaction-submitting="transaction_submitting"
              :student-name="student_name"
              :merchant-name="selected_payment_method.merchant_name"
              :payable-amount="payable_amount"
              :charge-percentage="selected_payment_method.charge_percentage"
              :account-number="selected_payment_method.account_number"
              :instructions="selected_payment_method.instructions"
              :paymentMethodId="selected_payment_method.id"
              :nb="selected_payment_method.nb"
              :other_payment_types="selected_payment_method.other_payment_types"
              :payment_type="selected_payment_method.payment_type"
              @transaction-success="transaction_success = true"
          >
          </payment-transaction>

      </div>
      </div>
    </container>
</template>

<script>
    import PaymentTransaction from './payment-transaction.vue';
    import axios from "../../../../store/axios";
    import Btn from '../../../../components/btn.vue';
    import Container from "../../../layouts/container";
    import PaymentMethods from "../../../components/payments/payment-methods";
    export default{
        components: {
          PaymentMethods,
          Container,
            PaymentTransaction,
            Btn
        },

       data() {
            return {
                student_name: '',
                selected_payment_method_id: null,
                payment_methods: [],
                payable_amount: 0,
                transaction_success: this.$route.query['transaction-success'],
                transaction_submitting: false,
                nb:'',
                other_payment_types:'',
                payment_type:'',
                loading: true,
            }
        },

        computed:{
            token(){
              return this.$route.query.token;
            },
            selected_payment_method(){

                let filtered = [];

                if( Array.isArray(this.payment_methods ) ) {

                    if( this.payment_methods.length === 1) {   
                        filtered = this.payment_methods;
                    } else if( this.payment_methods.length > 1 && this.selected_payment_method_id ) {
                        filtered = this.payment_methods.filter( (m) => m.id === this.selected_payment_method_id );
                    }
                }
                
                const paymentMethod = filtered.length > 0 ? filtered[0] : {};

                return {
                    merchant_name : paymentMethod.title,
                    icon_url : paymentMethod.icon_url,
                    account_type : paymentMethod.account_type,
                    account_number : paymentMethod.account_number,
                    instructions : paymentMethod.instructions,
                    payable_amount : paymentMethod.payable_amount,
                    charge_percentage : paymentMethod.charge_percentage,
                    id: paymentMethod.id,
                    nb: paymentMethod.nb,
                    other_payment_types:paymentMethod.other_payment_types,
                    payment_type:paymentMethod.payment_type
                }

            }
        },

        mounted(){
            
        },
        created(){

            axios().get(`/data-collection-forms/payment-methods`, { params: {token: this.token}} )
            .then(({data}) => {
                this.payment_methods = data.payment_methods;
                this.account_number = data.account_number;
                this.student_name = data.student_name;
                this.payable_amount = data.payable_amount;

                console.log(data);
            }).catch((err) =>{
                console.log(err)
            }).finally( () => this.loading = false );

        },
        methods: {
            chooseMethod(){
                
                
            }
        },
    }
</script>