<template>
     <section class="text-gray-600 body-font">
                <h3 class="sm:text-3xl text-xl font-medium title-font mb-4 text-gray-900 text-center">How to pay with <span class="text-blue-600 font-bold">Bkash</span></h3>

        <div class="container px-5 py-10 mx-auto">
            <div class="flex flex-wrap -mx-4 -mb-10 text-center">
                <div class="sm:w-1/2 mb-10 px-4">
                    <div class="rounded-lg h-auto overflow-hidden">
                        <img alt="content" class="object-cover object-center h-full w-full"
                            src="https://onlineshoppingbd.org/wp-content/uploads/2016/12/bKash_Payment_for_bdshop.jpg">
                    </div>
                    <p class="title-font font-medium text-gray-900 mt-2 mb-3">Payment using USSD</p>
                    
                </div>
                <div class="sm:w-1/2 mb-10 px-4">
                    <div class="rounded-lg h-auto overflow-hidden">
                        <img alt="content" class="object-cover object-center h-full w-full"
                            src="https://www.bkash.com/sites/default/files/userfiles/files/Bank%20Integration/16%20tk/Website-Process_APP.png">
                    </div>
                    <p class="title-font font-medium text-gray-900 mt-2 mb-3">Payment using bKash App</p>
                    
                </div>
            </div>
        </div>
    </section>
</template>
